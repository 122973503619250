// Brand Style One 
.brand-carousel-activation {
    padding: 0 50px;
    @media #{$sm-layout} {
        padding: 0 10px;
    }
    li {
        margin-top: 0;
        margin-bottom: 0;
        a {
            display: block;
            img {
                opacity: 0.8;
                transition: 0.4s;
            }
        }
        &:hover {
            a {
                img {
                    opacity: 1;
                    filter: contrast(0.5);
                }
            }
        }
    }
    &.rainbow-slick-arrow button.slick-arrow {
        width: 50px;
        height: 50px;
    }
    &.rainbow-slick-arrow button.slick-arrow i {
        display: block;
        font-size: 24px;
        color: #fff;
    }
    &.rainbow-slick-arrow button.slick-arrow::before {
        display: none;
    }
}


.brand-style-1 {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
        flex-basis: 25%;
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        margin: 0px 0px -2px -2px;
        border-color: var(--color-border);
        padding: 40px 0 40px 0;
        text-align: center;

        @media #{$large-mobile} {
            flex-basis: 33.33%;
        }

        @media #{$small-mobile} {
            flex-basis: 50%;
        }

        a {
            display: block;
            img {
                opacity: 0.8;
                transition: 0.4s;
            }
        }

        &:hover {
            a {
                img {
                    opacity: 1;
                    filter: contrast(0.5);
                }
            }
        }
    }
}

// Brand Style Two 
.brand-style-2 {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
        flex-basis: 16.666%;
        text-align: center;

        @media #{$md-layout} {
            flex-basis: 25%;
        }

        @media #{$sm-layout} {
            flex-basis: 25%;
        }

        @media #{$large-mobile} {
            flex-basis: 33.33%;
        }

        @media #{$small-mobile} {
            flex-basis: 50%;
        }

        a {
            display: block;

            img {
                opacity: 0.8;
                transition: 0.3s;
            }
        }

        &:hover {
            a {
                img {
                    opacity: 1;
                }
            }
        }
    }

    &.variation-2 {
        li {
            flex-basis: 25%;
            text-align: center;

            @media #{$sm-layout} {
                flex-basis: 33.33%;
            }

            @media #{$small-mobile} {
                flex-basis: 50%;
            }
        }
    }
}


.advance-brand {
    @extend %liststyle;
}


