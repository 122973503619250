.header-transparent-with-topbar {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;

    @media #{$md-layout} {
        position: relative;
    }

    @media #{$sm-layout} {
        position: relative;
    }

    .header-top-bar {
        background-color: transparent;

        @media #{$md-layout} {
            background-color: var(--color-darker);
        }

        @media #{$sm-layout} {
            background-color: var(--color-darker);
        }
    }

    .header-default {
        background-color: transparent;
        background-image: none;

        @media #{$md-layout} {
            background-color: var(--color-darker);
        }

        @media #{$sm-layout} {
            background-color: var(--color-darker);
        }
    }
}