/*----------------------
    Blog Styles  
-----------------------*/
.rainbow-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--color-blackest);
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: var(--radius);
    overflow: hidden;
    transition: all .5s;

    .inner {
        .thumbnail {
            overflow: hidden;
            a {
                display: block;
                overflow: hidden;
                img {
                    width: 100%;
                    transition: 0.5s;
                    overflow: hidden;
                }
            }
        }
        .content {
            display: flex;
            flex-wrap: wrap;
            padding: 30px;
            padding-top: 22px;

            @media #{$sm-layout} {
                padding: 30px 20px;
            }

            .descriptiion {
                margin-bottom: 0;
                margin-bottom: 13px;
            }

            .title {
                font-size: 20px;
                line-height: 1.5;
                transition: all .5s;
                margin-bottom: 0;
                margin-top: 15px;
                flex-basis: 100%;
            }
        }
    }

    &.card-list-view {
        height: 100%;

        .inner {
            display: flex;
            height: 100%;
            @media #{$large-mobile} {
                display: block;
            }

            .thumbnail {
                flex-basis: 46%;

                a {
                    display: block;
                    height: 100%;

                    img {
                        width: auto;
                        object-fit: cover;
                        height: 100%;
                    }
                }
            }

            .content {
                flex-basis: 54%;

                .title {
                    margin-bottom: 10px;
                }

                .descriptiion {
                    margin-bottom: 13px;
                    margin-top: 12px;
                }
            }
        }
    }

    &.content-transparent {
        position: relative;
        border: 0 none;
        .content {
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, .9) 100%);
            padding-top: 60px;

            @media #{$sm-layout} {
                padding-top: 20px;
            }

            .title {
                margin-bottom: 10px;
                @media #{$sm-layout} {
                    font-size: 17px;
                }
                a {
                    color: var(--color-white);
                }

                &:hover {
                    a {
                        color: var(--color-primary);
                    }
                }
            }

            .rainbow-meta-list {
                li {
                    color: #acaeb1;

                    a {
                        color: #acaeb1;
                    }
                }
            }
        }

        &.post-large {
            .content {
                .title {
                    font-size: 32px;
                    @media #{$sm-layout} {
                        font-size: 19px;
                    }
                }
            }
        }
    }

    &:hover {
        .inner {
            .thumbnail {
                a {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}


.rainbow-meta-list {
    @extend %liststyle;
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--color-gray);
        font-size: 14px;


        a {
            color: var(--color-gray);
            transition: 0.5s;
        }

        &.separator {
            margin: 0 .8rem;
        }
    }
}