/*------------------------
    Back to Top  
--------------------------*/
.rainbow-back-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 999;
    width: 50px;
    height: 50px;
    line-height: 46px;
    border-radius: 50%;
    text-align: center;
    z-index: 999!important;
    background-color: #0f0f11;
    background-color: var(--color-blackest);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    i {
        color: #fff;
        transition: .3s;
        font-size: 20px;
    }
    &:hover {
        i {
            color: var(--color-primary)!important;
        }
    }
}