/*---------------------
    Tab Styles  
-----------------------*/
.rainbow-default-tab {
    position: relative;
    // Tab Button 
    .tab-button {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        border-bottom: none;
        .tabs__tab {
            margin: 0;
            .nav-link {
                color: var(--color-white);
                padding: 24px 44px;
                margin-bottom: -2px;
                outline: none;
                border: 0 none;
                @media #{$lg-layout} {
                    padding: 24px 24px;
                }
                @media #{$md-layout} {
                    padding: 24px 24px;
                }
                @media #{$sm-layout} {
                    padding: 24px 20px;
                }
                &.active {
                    background: var(--color-lessdark);
                    border-color: transparent;
                }

                
            }
        }
        .rainbow-tab-button {
            button {
                padding: 0;
                margin: 0;
                background: transparent;
                border: 0 none;
                color: var(--color-white);
            }
        }
    }

    // Tab Content 
    .rainbow-tab-content {
        background: var(--color-lessdark);
        padding: 40px;
        @media #{$sm-layout} {
            padding: 30px;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.style-two {
        .tab-button {
            margin-bottom: 30px;
            .tabs__tab {
                button {
                    border-radius: var(--radius-small);
                }
            }
        }
        .rainbow-tab-content {
            border-radius: var(--radius-small);
        }
    }

}


.rainbow-default-tab::before {
    //background: linear-gradient(180deg, var(--color-lessdark) 20%, rgba(19, 19, 19, 0.4));
    background-color: var(--color-primary);
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
    width: 100%;
    height: 70%;
    content: "";
    left: 0;
    bottom: 0;
    position: absolute;
    z-index: -1;
    border-radius: 10px;
    opacity: 0.5;
    backdrop-filter: blur(20px);
    // @media #{$md-device} {
    //     width: 80%;
    //     left: 10%;
    // }
}

.rainbow-no-padding{
    padding: 0 !important;
}