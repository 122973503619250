/*------------------------
    Featue Styles  
--------------------------*/
.rbt-feature-main {
    padding-bottom: 0;
    border-radius: 10px;
    @media #{$sm-layout} {
        padding: 30px;
    }
    .feature-left-top {
        max-width: 500px;
    }
    .feature-left-bottom {
        max-width: 500px;
        .button-group {
            margin-bottom: 50px;
        }
    }
}