




/*------------------------------
    Gallery Styles  
--------------------------------*/
.rbt-temaplte-gallery-wrapper {
    .thumbnail {
        img {
            width: 100%;
            border-radius: 10px;
        }
    }
}








.template-slider-wrapper {
    .sub-title {
        color: var(--color-primary);
    }
    .title {
        font-weight: var(--p-black);

    }
    .description {
        max-width: 900px;
    }
}













